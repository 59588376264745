/* Google Font Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
.active-link {
  color: red !important; /* Change to the color you want for active links */
  /* Add any other styles you want for active links */
}
.main{
  overflow:hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background:  #0b212d;
  padding: 6px 14px;
  z-index: 99;
  transition: all 0.5s ease;
}

.sidebar.open {
  width: 250px;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  /* color: #fff;
  font-size: 20px;
  border: solid 2px;
  padding:8px;
  font-weight: 600;*/
  opacity: 0; 
  transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}
.dot {
  width: 9px;
  height: 9px;
  background-color: rgb(245, 241, 15);
  border-radius: 50%;
  display: inline-block;
}
.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
  text-align: right;
}

.sidebar i,
.sidebar li .profile-details {
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
  display: flex; /* Added */
  align-items: center; /* Added */
}

.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}

.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}

.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar.open li .tooltip {
  display: none;
}

.sidebar input {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background:  #0b212d;
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #1d1b31;
  color: #fff;
}

.sidebar.open .bx-search:hover {
  background: #1d1b31;
  color: #fff;
}

.sidebar .bx-search:hover {
  background: #fff;
  color: #11101d;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background:  #0b212d;
}

.sidebar li a:hover {
  background:#0b212d;
  
}

.sidebar li a .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
 
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color:rgb(0, 255, 255);
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  margin-left:-23px !important;
  font-size: 18px;
  border-radius: 12px;
}

.sidebar li.profile {
  position: fixed;
  height: 60px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  background: #1d1b31;
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.open li.profile {
  width: 250px;
}

.sidebar li .profile-details img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}

.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}

.sidebar li.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #1d1b31;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}

.home-section {
  position: relative;
  background: url("/public/img/banner.jpg") no-repeat;
  background-size: cover; /* Adjusted background-size */
  height: 80vh;
  top: 0;
  overflow:hidden;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 1;
}
@media(max-width:768px){
  .logo-container1{
    display:none;
  }
}
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 915px) 
  and (orientation: landscape) {
  .home-section{
    min-height:130vh !important;
  }
  .logo-container1{
    display:none;
  }
}


.home-sectionothers {
  position: relative;
  background: #E4E9F7;
  top: 0;
  transition: all 0.5s ease;
 
}
.home-sectionothers-toggle{
  transition: all 0.5s ease;
  top: 0;
  position: relative;
  background: #E4E9F7;
  left: 250px;
  width: calc(100% - 250px);
  transition: all 0.5s ease;
}

.home-section-toggle{
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  position: relative;
  z-index: 1;
  display: flex;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: -30px 0 0 0;
}

@media (max-width: 768px) {
  .sidebar{
    display: none;
  }
  .home-section,.home-sectionothers,.home-sectionothers-toggle{
    left:0;
    width:100%;
    overflow: hidden;
  }
}

.animation {
  height: 74.5vh;
  display: flex;
  min-width:50vw;
  align-items: center;
  justify-content:space-between;
  flex-direction: column;
}
.mobile-logo{
width:100px;
height:100px;

}
@media (max-width: 768px) {
.text{
  flex-direction:column;
display:none;
}
.animation{
  min-width:100vw;
}
.logo-container{
  display: none;
}
.mobile-logo{
  width:200px;
  height:200px;
  
  }
}

.tag-list {
  width: 30rem;
  max-width: 90vw;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 1rem 0;
  position: relative;
  /* padding: 1.5rem 0; */
  overflow: hidden;
}

.loop-slider {
  .inner {
    display: flex;
    width: fit-content;
    animation-name: loop;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: var(--direction);
    animation-duration: var(--duration);
  }
}

.tag {
  display: flex;
  align-items: center;
  gap: 0 0.2rem;
  color: #e2e8f0;
  font-size: 0.9rem;
  background-color:  #0b212d;
  border-radius: 0.4rem;
  padding: 0.7rem 1rem;
  margin-right: 1rem; 
  box-shadow: 
    0 0.1rem 0.2rem rgb(0 0 0 / 20%),
    0 0.1rem 0.5rem rgb(0 0 0 / 30%),
    0 0.2rem 1.5rem rgb(0 0 0 / 40%);
  
  span {
    font-size: 1rem;
    color: #f19203;
  }
}


.fade {
  pointer-events: none;
  background: linear-gradient(90deg, #1e293b, transparent 30%, transparent 70%, #1e293b);
  position: absolute;
  inset: 0;
}

@keyframes loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
.logo{
  width:300px;
  height:300px;
  

}
.logo-container{
  height:74.5vh;
  display:flex;
  width:100%;
  justify-content:center;
  align-items: center;
}
.logo-container1{
  height:74.5vh;
  display:flex;
  width:100%;
  justify-content:center;
  align-items: center;
}
@media(max-width:768px){
  .logo-container1{
    display:none;
  }
}
.nav {
  display:none;
}

.link {
  display: flex;

  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #fdfafa;
}

.link span {
  font-size: 10px;
  margin-top: 5px;
}
.extra{
  display:none;
}
@media (max-width: 768px) {
.nav{
position: fixed;
z-index:2;
bottom: 0;
width: 100%;
background-color:   #0b212d;
display: flex;
justify-content: space-around;
border-top: 1px solid #ccc;
padding: 10px 0;
}
.extra{
  display:inline;
}
}
.social-icons {
  position: fixed;
  top: 50%;
  right: 1px;
  padding:10px;
  transform: translateY(-50%);
  display: flex;
  background-color:#124694;
  flex-direction: column;
  align-items: center;
  z-index:2;
}

.icon-link {
  margin-bottom: 10px;
  text-decoration: none;
  color: #000;
}

.icon-link i {
  font-size: 24px;
}
.logo_name{
  display:flex;
  align-items:center;
  justify-content: flex-start;
  transition: all 0.5s ease !important;

}
.mark{
  display:flex;
  background-color: #0b212d;
  transition: all 0.5s ease;
}
.img {
  width: 150px;
  /* margin-left: 10px; */
  transition: all 0.5s ease;
  height: 50px;
}


.mark span{
  align-self: flex-start !important;
  margin-top:-8px;
  color:white;
  transition: all 0.5s ease;
  font-size:28px;
  margin-left:-11.5px !important;

}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.back-to-top{
  color:white !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/**
* Template Name: Moderna
* Template URL: https://bootstrapmade.com/free-bootstrap-template-corporate-moderna/
* Updated: May 7 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #444;
}

a {
  color: #4fa6d5;
  text-decoration: none;
}

a:hover {
  color: #45beff;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-primary {
  font-family: "Roboto", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 60px;
  z-index: 99999;
  background: #68A4C4;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #85b6cf;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 80px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: #1e4356;
}

#header.header-transparent {
  background: none;
}

#header.header-scrolled {
  background: rgba(30, 67, 86, 0.8);
  height: 60px;
}

#header .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 4px 0;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

#main {
  margin-top: 80px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #a2cce3;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #1c3745;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #68A4C4;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 768px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(17, 38, 48, 0.9);
  transition: 0.3s;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #1e4356;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #68A4C4;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #68A4C4;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 90vh;
  overflow: hidden;
  position: relative;
}

#hero::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 130%;
  height: 95%;
  background: linear-gradient(to right, rgba(30, 67, 86, 0.8), rgba(30, 67, 86, 0.6)), url("/public/img/hero-bg.jpg") center top no-repeat;
  z-index: 0;
  border-radius: 0 0 50% 50%;
  transform: translateX(-50%) rotate(0deg);
}

#hero::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 130%;
  height: 96%;
  background: #68A4C4;
  opacity: 0.3;
  z-index: 0;
  border-radius: 0 0 50% 50%;
  transform: translateX(-50%) translateY(18px) rotate(2deg);
}

#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

#hero p {
  width: 80%;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}

#hero .btn-get-started {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  animation-delay: 0.8s;
  border: 2px solid #68A4C4;
}

#hero .btn-get-started:hover {
  background: #68A4C4;
  color: #fff;
  text-decoration: none;
}

@media (min-width: 1024px) {
  #hero p {
    width: 60%;
  }

  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-width: 768px) {
  #hero::after {
    width: 180%;
    height: 95%;
    border-radius: 0 0 50% 50%;
    transform: translateX(-50%) rotate(0deg);
  }

  #hero::before {
    top: 0;
    width: 180%;
    height: 94%;
    border-radius: 0 0 50% 50%;
    transform: translateX(-50%) translateY(20px) rotate(4deg);
  }
}

@media (max-width: 575px) {
  #hero h2 {
    font-size: 30px;
  }

  #hero::after {
    left: 40%;
    top: 0;
    width: 200%;
    height: 95%;
    border-radius: 0 0 50% 50%;
    transform: translateX(-50%) rotate(0deg);
  }

  #hero::before {
    left: 50%;
    top: 0;
    width: 200%;
    height: 94%;
    border-radius: 0 0 50% 50%;
    transform: translateX(-50%) translateY(20px) rotate(4deg);
  }
}

/*--------------------------------------------------------------
# Hero No Slider Section
--------------------------------------------------------------*/
#hero-no-slider {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  text-align: center;
}

#hero-no-slider::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(30, 67, 86, 0.8), rgba(30, 67, 86, 0.6)), url("/public/img/hero-bg.jpg") center top no-repeat;
}

#hero-no-slider h2 {
  color: #fff;
  margin-bottom: 15px;
  font-size: 48px;
  font-weight: 700;
}

#hero-no-slider p {
  color: #fff;
}

#hero-no-slider .btn-get-started {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  animation-delay: 0.8s;
  border: 2px solid #68A4C4;
}

#hero-no-slider .btn-get-started:hover {
  background: #68A4C4;
  color: #fff;
  text-decoration: none;
}

@media (max-width: 575px) {
  #hero-no-slider h2 {
    font-size: 30px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  background-color: #f3f8fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #68A4C4;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/

.breadcrumbs {
  padding: 15px 0;
  background-color:#0b212d;
  min-height: 40px;
  position:relative;
}
.header-logo{
  padding:0 20px 0 10%;
  display:flex;
  justify-content:space-between!important;
  align-items:center;
}
.header-logo h2{
  color:white;
}
@media(max-width:768px){
  .header-logo{
    display:flex !important;
    padding-left:10px;
    flex-direction:row !important;
  }
  /* .header-logo h2{
    font-size:3px !important;
  } */
 
  .logo-name{
    width:0;
  }}
/* .breadcrumbs::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 105px 105px; /* Adjust thickness and angle */
  /* border-color: transparent transparent transparent #f0f0f0;
  transform-origin: 0 0;
  transform: rotate(45deg);  */
/* } */ 
.breadcrumbs h2 {
  font-size: 28px;
  color:white;
  font-weight:bold;
}
@media(max-width:768px){

  .breadcrumbs h2 {
    font-size: 28px;
    color:white;
    font-weight:bold;
  }
}

.breadcrumbs ol {
  display: flex;

  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }


  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Our Services
--------------------------------------------------------------*/
.services {
  padding-bottom: 20px;
  margin-top:-8%;
  
}
@media(max-width:768px){
  .services{
    /* margin-top:-25%; */
  }
}

.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  margin: 0 0 40px 0;
  background: #fff;

  transition: all 0.3s ease-in-out;
  text-align: center;
  border: 1px solid #fff;
}
.services .icon-box-pink{
  box-shadow: 0 0 10px rgba(255, 192, 203, 0.5);
}
.services .icon-box-cyan{
  box-shadow: 0 0 10px rgba(173, 216, 230, 0.5);
}
.services .icon-box-green{
  box-shadow: 0 0 10px rgba(2, 248, 2, 0.5);
 
}
.services .icon-box-blue{
  box-shadow: 0 0 10px rgba(40, 85, 102, 0.5);

}
.services .icon-box-red{
  box-shadow: 0 0 10px rgba(255, 99, 71, 0.5);
}
.services .icon {
  margin: 0 auto 20px auto;
  padding-top: 17px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 72px;
  height: 72px;
}

.services .icon i {
  font-size: 36px;
  line-height: 1;
}

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .title a {
  color: #111;
}

.services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.services .icon-box-pink .icon {
  background: #fceef3;
}

.services .icon-box-pink .icon i {
  color: #ff689b;

}

.services .icon-box-pink:hover {
  border-color: #ff689b;
}

.services .icon-box-cyan .icon {
  background: #e6fdfc;
}

.services .icon-box-cyan .icon i {
  color: #3fcdc7;
}

.services .icon-box-cyan:hover {
  border-color: #3fcdc7;
}

.services .icon-box-green .icon {
  background: #eafde7;
}

.services .icon-box-green .icon i {
  color: #41cf2e;
}

.services .icon-box-green:hover {
  border-color: #41cf2e;
}

.services .icon-box-blue .icon {
  background: #e1eeff;
}

.services .icon-box-blue .icon i {
  color: #2282ff;
}

.services .icon-box-blue:hover {
  border-color: #2282ff;
}


.services .icon-box-red .icon {
  background: #f1cfd7;
}

.services .icon-box-red .icon i {
  color: #e99595;
}

.services .icon-box-red:hover {
  border-color: #ee6767;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/

.why-us .container {
  box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
  background: #fff;

  /* z-index:-2; */
}


.why-us .icon-box+.icon-box {
  margin-top: 50px;
}

.why-us .icon-box .icon {
  float: left;
  display: flex;
  margin:5px;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  background: #f3f8fa;
  border-radius: 6px;
  transition: 0.5s;
}
@media(max-width:768px){
  .why-us .icon-box .icon {
   /* display:none; */
  }
 
}

.why-us .icon-box .icon i {
  color: #68A4C4;
  font-size: 32px;
}

.why-us .icon-box:hover .icon {
  background: #68A4C4;
}

.why-us .icon-box:hover .icon i {
  color: #fff;
}

.why-us .icon-box .title {
  margin-left: 95px;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
  text-transform: uppercase;
}



.why-us .icon-box .title a {
  color: #343a40;
  transition: 0.3s;
}

.why-us .icon-box .title a:hover {
  color: #68A4C4;
}

.why-us .icon-box .description {
  margin-left: 95px;
  line-height: 24px;
  font-size: 14px;
}

@media(max-width:768px){
  .why-us .icon-box .title {
   margin-left:0;
  }
  .why-us .icon-box .description {
    margin-left:0;
   }
}
.why-us .video-box {
  position: relative;
}

.why-us .video-box img {
  padding-top: 15px;
  padding-bottom: 15px;
}

.why-us .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#68A4C4 50%, rgba(104, 164, 196, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.why-us .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.why-us .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(104, 164, 196, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.why-us .play-btn:hover::after {
  border-left: 15px solid #68A4C4;
  transform: scale(20);
}

.why-us .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .row+.row {
  margin-top: 100px;
}

.features h3 {
  font-weight: 400;
  font-size: 24px;
}

.features ul {
  list-style: none;
  padding: 0;
}

.features ul li {
  padding-bottom: 10px;
}

.features ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #68A4C4;
}

.features p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about h3 {
  font-weight: 400;
  font-size: 26px;
}

.about ul {
  list-style: none;
  padding: 0;
}

.about ul li {
  padding-bottom: 10px;
}

.about ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #68A4C4;
}

.about p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Facts
--------------------------------------------------------------*/
.facts .counters span {
  font-size: 48px;
  display: block;
  color: #68A4C4;
}

.facts .counters p {
  padding: 0;
  margin: 0 0 20px 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients .swiper-wrapper {
  height: auto;
}

.clients .swiper-pagination {
  margin-top: 30px;
  position: relative;
}

.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.2);
}

.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #68A4C4;
}

/*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
.skills {
  padding: 60px 0;
}

.skills .progress {
  height: 35px;
  margin-bottom: 10px;
}

.skills .progress .skill {
  line-height: 35px;
  padding: 0;
  margin: 0 0 0 20px;
  text-transform: uppercase;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
  margin: 0 20px 0 0;
}

.skills .progress-bar {
  width: 1px;
  text-align: left;
  transition: 0.9s;
}

/*--------------------------------------------------------------
# Tetstimonials
--------------------------------------------------------------*/
.testimonials {
  padding: 60px 0;

}


.testimonials .section-header {
  margin-bottom: 20px;
}

.testimonials .testimonials-carousel {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
}

.testimonials .testimonial-item .testimonial-img {
  width: 120px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #fbfcfd;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #68A4C4;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #68A4C4;
}

/*--------------------------------------------------------------
# Service Details
--------------------------------------------------------------*/
.service-details {
  padding-bottom: 10px;
}

.service-details .card {
  border: 0;
  padding: 0 30px;
  margin-bottom: 60px;
  position: relative;
}

.service-details .card-img {
  width: calc(100% + 60px);
  margin-left: -30px;
  overflow: hidden;
  z-index: 1;
  border-radius: 0;
}

.service-details .card-img img {
  width: 100%;
  height:50vh;
  transition: all 0.3s ease-in-out;
}
@media(max-width:768px){

.service-details .card-img img {
  width: 100%;
  height:100%;
  transition: all 0.3s ease-in-out;
}

}
.card-body{
  position: relative;
  z-index: 1; /* Ensure content appears above the background */
  padding: 20px;
  /* margin-top: -60px; */
  color: #000; /* Normal text color */
}

/* .service-details .card-body {
  z-index: 1;

  background:url('/public/img/logo.png');
  background-color:white;
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: center;
  opacity:0.5;
  object-fit: cover;
  border-top: 4px solid #fff;
  padding: 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin-top: -60px;
  transition: 0.3s;
} */
@media(max-width:768px){
  .card-body{
    background-size:30%;
  }
}
.card-body:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/img/logo.png');
  /* background-size: cover; */
  background-size: 25%;
  background-color: white;
  background-position: center;
  /* border-top: 4px solid #fff; */
  padding: 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  background-repeat: no-repeat;
  /* Set opacity for the background image */
  opacity: 0.4; /* Adjust as needed */
  z-index: -1; /* Place the pseudo-element behind the content */
}

@media(max-width:768px){
  .card-body:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/public/img/logo.png');
    /* background-size: cover; */
    background-size: 50%;
    background-color: white;
    background-position: center;
    /* border-top: 4px solid #fff; */
    padding: 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    background-repeat: no-repeat;
    /* Set opacity for the background image */
    opacity: 0.4; /* Adjust as needed */
    z-index: -1; /* Place the pseudo-element behind the content */
  }
}
.service-details .card-title {
  font-weight: 700;
  color:rgb(4, 96, 99);
  text-align: center;
  margin-bottom: 20px;
}

.service-details .card-title a {
  color: #253c47;
  transition: 0.3s;
}

.service-details .card-text {
  color: #272626;
}

.service-details .read-more a {
  color: #777777;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  transition: 0.3s;
}

.service-details .read-more a:hover {
  color: #68A4C4;
}

.service-details .card:hover img {
  transform: scale(1.1);
}

.service-details .card-body{
  border-bottom: solid 8px #68A4C4;
  border-radius:10px;
  border-top: solid 8px #68A4C4;
  border-radius:10px;
  transition: all 0.5s ease;
}


.service-details .card:hover .card-body .card-title a {
  color: #68A4C4;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}

.pricing .box {
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
}

.pricing h3 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 28px;
}

.pricing h4 {
  font-size: 46px;
  color: #68A4C4;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 25px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding-bottom: 12px;
}

.pricing ul i {
  color: #68A4C4;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .get-started-btn {
  background: #1e4356;
  display: inline-block;
  padding: 6px 30px;
  border-radius: 20px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  transition: 0.3s;
}

.pricing .get-started-btn:hover {
  background: #68A4C4;
}

.pricing .featured {
  z-index: 10;
  margin: -30px -5px 0 -5px;
}

.pricing .featured .get-started-btn {
  background: #68A4C4;
}

.pricing .featured .get-started-btn:hover {
  background: #85b6cf;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio {
  padding-bottom: 60px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 0 35px 0;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  margin: 0 12px 5px 12px;
  display: inline-block;
  padding: 0 4px 6px 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #444;
  transition: all 0.3s ease-in-out;
  border-bottom: 2px solid #fff;
  font-family: "Roboto", sans-serif;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  border-color: #68A4C4;
  color: #68A4C4;
}

.portfolio .portfolio-item {
  background: #1e4356;
  overflow: hidden;
  min-height: 200px;
  position: relative;
  border-radius: 4px;
  margin: 0 0 30px 0;
}

.portfolio .portfolio-item img {
  transition: all 0.3s ease-in-out;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  transition: all 0.3s linear;
  text-align: center;
  top: 10%;
  left: 0;
  right: 0;
}

.portfolio .portfolio-item .portfolio-info h3 {
  font-size: 22px;
  color: #fff;
  font-weight: bold;
}

.portfolio .portfolio-item .portfolio-info a i {
  color: rgba(255, 255, 255, 0.6);
  font-size: 28px;
  transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-info a i:hover {
  color: white;
}

.portfolio .portfolio-item:hover img {
  opacity: 0.6;
  transform: scale(1.1);
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  top: calc(50% - 30px);
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #68A4C4;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #68A4C4;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(30, 67, 86, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  background: #fff;
  padding-bottom: 30px;
}

.team .member {
  margin-bottom: 20px;
  overflow: hidden;
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: -40px;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: bottom ease-in-out 0.4s;
  text-align: center;
  background: rgba(30, 67, 86, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #fff;
  margin: 0 12px;
  display: inline-block;
}

.team .member .social a:hover {
  color: #68A4C4;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin: 15px 0 5px 0;
  font-size: 18px;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 15px;
  color: #68A4C4;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #666;
}

.team .member:hover .social {
  bottom: 0;
  opacity: 1;
  transition: bottom ease-in-out 0.4s;
}

.team .member:hover .social a,
.team .member:hover .social i {
  line-height: 0;
  font-size: 18px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
  padding-bottom: 30px;
}

.contact .info-box {
  color: #444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
  margin-bottom: 30px;
}

.contact .info-box i {
  font-size: 32px;
  color: #68A4C4;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #a2cce3;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #666;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
  margin-bottom: 30px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input::focus,
.contact .php-email-form textarea::focus {
  background-color: #68A4C4;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #68A4C4;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

.contact .php-email-form button[type=submit]:hover {
  background: #8dbad2;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Map
--------------------------------------------------------------*/
.map {
  padding: 0;
  /* margin-bottom: -6px; */
}

.map iframe {
  width: 100%;
  height: 380px;
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}

.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 440px;
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #1e4356;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: #68A4C4;
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #72afce;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li+li {
  padding-left: 20px;
}

.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}

.blog .entry .entry-meta a {
  color: #777777;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .entry .entry-content p {
  line-height: 24px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #68A4C4;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #7aafcb;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #1e4356;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #4c99c1;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #255269;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: #68A4C4;
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li+li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}

.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #1e4356;
}

.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
  color: rgba(30, 67, 86, 0.5);
  margin-right: 5px;
}

.blog .blog-author p {
  font-style: italic;
  color: #b7b7b7;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
  width: 60px;
}

.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #68A4C4;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #1e4356;
}

.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #2b607c;
  margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #b1d0e1;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #b1d0e1;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #1e4356;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #255269;
}

.blog .blog-pagination {
  color: #387ea2;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: #1e4356;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: #68A4C4;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: #fff;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #1e4356;
  position: relative;
}

.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #68A4C4;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: #77adca;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li+li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #1e4356;
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: #68A4C4;
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item+.post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: #1e4356;
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: #68A4C4;
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #aaaaaa;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #3f8db5;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #e4eff5;
  display: inline-block;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #68A4C4;
  background: #68A4C4;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #bedae8;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #0b212d;

  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-newsletter {
  padding: 50px 0;
  background: #0d2735;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  color: #a2cce3;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
}

#footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  margin: 3px;
  background: #68A4C4;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}

#footer .footer-newsletter form input[type=submit]:hover {
  background: #468db3;
}

#footer .footer-top {
  background: #0d2735;
  border-top: 1px solid #17455e;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 18px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  color: #a2cce3;
  font-weight: 600;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #1e4356;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #68A4C4;
  color: #fff;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 18px;
  font-weight: 600;
  color: #a2cce3;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #a2cce3;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #a2cce3;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
}

#footer .copyright {
  /* text-align: center; */
  margin-left:40%;
  padding-top: 20px;
}.count{
  margin-left:30%;
}
@media(max-width:768px){
  #footer .copyright {
    /* text-align: center; */
    margin-left:0%;
    display:flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;

  }.count{
    margin-left:0%;
  }
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

#footer .credits a {
  color: #a2cce3;
}

/*--------------------------------------------------------------
# Team Section
--------------------------------------------------------------*/
.team .team-member {
  box-shadow: 0px 2px 15px color-mix(in srgb, var(--default-color), transparent 90%);
  position: relative;
  border-radius: 5px;
  transition: 0.5s;
  padding: 30px;
  height: 100%;
}

@media (max-width: 468px) {
  .team .team-member {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }
}

.team .team-member .pic {
  overflow: hidden;
  width: 150px;
  border-radius: 50%;
  flex-shrink: 0;
}

.team .team-member .pic img {
  transition: ease-in-out 0.3s;
}

.team .team-member:hover {
  transform: translateY(-10px);
}

.team .team-member .member-info {
  padding-left: 30px;
}

@media (max-width: 468px) {
  .team .team-member .member-info {
    padding: 30px 0 0 0;
    text-align: center;
  }
}

.team .team-member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
}

.team .team-member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.team .team-member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: color-mix(in srgb, var(--default-color), transparent 85%);
  bottom: 0;
  left: 0;
}

@media (max-width: 468px) {
  .team .team-member span::after {
    left: calc(50% - 25px);
  }
}

.team .team-member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}

.team .team-member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
}

@media (max-width: 468px) {
  .team .team-member .social {
    justify-content: center;
  }
}

.team .team-member .social a {
  background: color-mix(in srgb, var(--default-color), transparent 94%);
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 36px;
  height: 36px;
}

.team .team-member .social a i {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  font-size: 16px;
  margin: 0 2px;
}

.team .team-member .social a:hover {
  background: var(--accent-color);
}

.team .team-member .social a:hover i {
  color: var(--contrast-color);
}

.team .team-member .social a+a {
  margin-left: 8px;
}

.courses .course-item {
  border-radius: 5px;

  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.courses .course-content {
  padding: 15px;
}

.courses .course-content h3 {
  font-weight: 700;
  font-size: 20px;
}

.courses .course-content h3 a {
  color: var(--heading-color);
  transition: 0.3s;
}

.courses .course-content h3 a:hover {
  color: var(--accent-color);
}

.courses .course-content .category {
  background: var(--accent-color);
  color: var(--contrast-color);
  font-size: 14px;
  padding: 6px 14px;
  margin: 0;
  border-radius: 5px;
}

.courses .course-content .price {
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.courses .course-content .description {
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.courses .trainer {
  padding-top: 15px;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.courses .trainer .trainer-profile img {
  max-width: 50px;
  border-radius: 50px;
}

.courses .trainer .trainer-profile .trainer-link {
  padding-left: 10px;
  font-weight: 600;
  font-size: 16px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  transition: 0.3s;
}

.courses .trainer .trainer-profile .trainer-link:hover {
  color: var(--accent-color);
}

.courses .trainer .trainer-rank {
  font-size: 18px;
  color: color-mix(in srgb, var(--default-color), transparent 60%);
}

.courses .trainer .trainer-rank .user-icon {
  font-size: 22px;
}
