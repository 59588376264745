/* PreviewForm.css */
.success-message-enter {
    opacity: 0;
    transform: translateY(-50%);
  }
  
  .success-message-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
  }
  .preview-submitted{
    display:flex;
    height:60vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .success-message-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .success-message-exit-active {
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity 500ms, transform 500ms;
  }
  