

.apply-form{
margin-top:1px;
  /* z-index:-1; */

  margin-bottom:0px;
  min-height:70vh;
 
}

.boxings{
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    margin-top:64px;
   
}
.label{
  font-size:13px;
  margin-left:4%;
}
.forms h1{
  color:rgb(8, 8, 117);
}
.captcha{

  margin-left:10px;
  /* align-items: flex-start; */
  margin-bottom:10px;
}
.validemail{
font-size:10px;
}

.boxing h1{
  color: #06175f;
}

.forms{
  margin-left:10%;
    display:flex;
    border-top:solid 6px #06175f;
    margin-top:10px;
  
    margin-bottom:20px;
    flex-direction:column;
    background-color:rgb(255, 255, 255);
    padding:10px;

   
}
.forms input{
    margin:9px;
    padding:5px;
    outline:none;
 
    font-size:12px;
    border:none;
    color:black;
   border-bottom: solid 1.3px rgb(88, 86, 86);

    /* border-radius:5px 0 5px 0;
    border-color:none; */
    
}


.forms button{
    padding:9px;
    background-color: #06175f;
    border:none;
    color:rgb(248, 246, 241);
    font-weight:bold;
    font-size:15px;
    width:80px;
    border-radius:2px;
    cursor:pointer;
    transition:all 0.5s ease;
}

.forms button:hover{
  
padding:9px;
    background-color:rgb(94, 10, 94);
    color:white;
}

@media (max-width: 768px) {
.boxing{
  flex-direction:column;

  
}
.boximg{
  width:100vw;
  margin-left:25%;

  }
  .boximg img{
    width:50vw;
   display:none;
    height:30vh;
  }
  
  .forms{
  
  margin-left:20%;
  }
#button{
  font-size:10px;
  padding:6px;
  width:50px;
}


}